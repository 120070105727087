@import '../../scss/fonts.scss';
@import '../../scss/colors.scss';
@import '../../scss/mixins.scss';

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;

  @include for-phone-only {
    width: 100%;
  }

  .input, .textarea {
    font-family: $font-family;
    width: 100%;
    margin: 0 10px;
    display: block;
    box-sizing: border-box;
    display: block;
    border: none;
    padding: 10px 0;
    margin-bottom: 1rem;
    font-size: 20px;
    outline: none;
    transition: all 0.2s ease-in-out;
    background: none;
    border-bottom: 1px solid $main-text;
    color: $main-text;
    line-height: 1.5;

    &::placeholder {
      transition: all 0.2s ease-in-out;
      color: $main-text;
      font: 18px $font-family;
    }

    &:focus,
    &.populated {
      padding-left: 1rem;
      border-color: $main;
      // padding-bottom: 12px;

      &::placeholder {
        color: transparent;
      }
    }
  }

  .textarea {
    height: 200px;
    margin-top: 1rem;
  }

  &.dark {
    .input, .textarea {
      color: $text-dark;
      border-bottom: 1px solid $text-dark;
      &::placeholder {
        color: $text-dark;
      }
      &:focus, &.populated {
        border-color: $main-dark;
      }
    }
  }

  // .button {
  //   transition: all 0.2s ease-in-out;
  //   font: 18px $font-family;
  //   border: none;
  //   background: $main;
  //   color: $main-text;
  //   padding: 20px;
  //   width: 100%;
  //   text-transform: uppercase;
  //   letter-spacing: 5px;

  //   &:hover {
  //     background: $main2;
  //     cursor: pointer;
  //   }
  // }

  // &.dark {
  //   .button {
  //     background: $main-dark;
  //     color: $text-dark;

  //     &:hover {
  //       background: $main3-dark;
  //       color: $main3-text-dark;
  //     }
  //   }
  // }

  .sk-folding-cube {
    display: none;
    margin: 0 auto;
    width: 18px;
    height: 18px;
    position: relative;
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg);
  }

  .sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1); 
  }
  .sk-folding-cube .sk-cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $main;
    -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
            animation: sk-foldCubeAngle 2.4s infinite linear both;
    -webkit-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  .sk-folding-cube .sk-cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
            transform: scale(1.1) rotateZ(90deg);
  }
  .sk-folding-cube .sk-cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
            transform: scale(1.1) rotateZ(180deg);
  }
  .sk-folding-cube .sk-cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
            transform: scale(1.1) rotateZ(270deg);
  }
  .sk-folding-cube .sk-cube2:before {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
  }
  .sk-folding-cube .sk-cube3:before {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s; 
  }
  .sk-folding-cube .sk-cube4:before {
    -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
  }

  &.sending {
    button {
      pointer-events: none;

      &:before {
        display: none;
      }
      &:after {
        background: $main-text;
        width: 100%;
      }
    }
    &.dark {
      button:after {
        background: $main-text-dark;
      }
    }
    .button-text {
      display: none;
    }
    .sk-folding-cube {
      display: block;
    }

    .sk-folding-cube {
      // background-color: white;
      .dark & {
        .sk-cube:before {
          background-color: $main-dark;
        }
      }
    }
  }
}

.thanks {
  font-size: 24px;
  padding: 60px;
  color: $main-text;
  text-align: center;

  &.dark {
    color: $text-dark;
  }
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  &, &:hover, &:focus, &:active {
    -webkit-box-shadow: 0 0 0px 1000px #fef9fa inset !important;

    .dark & {
      -webkit-box-shadow: 0 0 0px 1000px #343434 inset !important;
      -webkit-text-fill-color: $text-dark !important;
    }
  }
}


@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  } 
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  }
}