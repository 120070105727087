@import '../../scss/keyframes.scss';
@import '../../scss/colors.scss';

.tagline {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  align-content: center;
  justify-content: center;
  margin-top: 20px;

  * {
    background: rgba(255,255,255,0.3);
    transition: 1s ease-in-out all;
  }
  &.dark * {
    background: rgba(255,255,255,0.6);
  }
  div {
    flex-grow: 1;
  }
  h2 {
    min-width: 670px;
    padding: 0 15px 0;
  }

  .next {
    width: 57px;
    height: 100%;
    margin-left: 20px;
    border: 0;
    background: transparent;
    background-image: url('/img/right-chevron.svg');
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    outline: #424242;
    transition: background-color 0.5s ease;
    position: relative;

    @media (max-width: 800px) {
      width: 51px;
    }

    &:before{
      content: '';
      position: absolute;
      display: block;
      width: 200%;
      height: 200%;
      opacity: .8;
      box-sizing: border-box;
      margin-left: -50%;
      margin-top: -50%;
      background-color: white;
      border: 1px solid white;
      -webkit-animation: pulse-ring 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
      animation: pulse-ring 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
      transition: background-color 0.5s ease;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: white;
      -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
      -webkit-animation: pulse-dot 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.5s infinite;
      animation: pulse-dot 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.5s infinite;
      transition: background-color 0.5s ease;
    }

    &:hover {
      &:before, &:after {
        background-color: #ff0000;
      }
      // filter: brightness(0.5) sepia(1) hue-rotate(-70deg) saturate(5);
    }
  }

  .right {
    background: transparent;
    display: flex;

    // &:before {
    //   content: " ";
    //   display: block;
    //   width: 51px;
    //   height: 100%;
    //   background: rgba(255,255,255,1);
    //   margin-left: 5px;
    //   background-image: url('/img/right-chevron.svg');
    //   background-size: 50%;
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   cursor: pointer;
    // }
    // &:hover:before {
    //   background: #ff0000;
    //   background-image: url('/img/right-chevron.svg');
    //   background-size: 50%;
    //   background-repeat: no-repeat;
    //   background-position: center;
    // }
  }

  .left:before {
    content: "";
    display: block;
    width: 56px;
    height: 51px;
  }

  @media (max-width: 800px) {
    .left {
      display: none;
    }
  }

  @media (max-width: 780px) {
    flex-direction: column;

    .right {
      height: 51px;
      justify-content: center;
      margin-top: 42px;
    }

    h2 {
      min-width: auto;
    }
  }

  @media (max-width: 500px) {
    h2 {
      font-size: 1.5em;
    }
  }
}
