@import '../../scss/keyframes.scss';

.intro-element.animated{
  -webkit-animation: intro-element 1s 1 ease-in-out forwards;
  animation: intro-element 1s 1 ease-in-out forwards;
}
.intro-element-reverse.animated{
  -webkit-animation: intro-element-reverse 1s 1 ease-in-out forwards;
  animation: intro-element-reverse 1s 1 ease-in-out forwards;
}
.slide-lr.animated{
  -webkit-animation: intro-element-slide-lr 1s 1 ease-in-out forwards;
  animation: intro-element-slide-lr 1s 1 ease-in-out forwards;
}
.slide-rl.animated{
  -webkit-animation: intro-element-slide-rl 1s 1 ease-in-out forwards;
  animation: intro-element-slide-rl 1s 1 ease-in-out forwards;
}
.slide-lr-short.animated{
  -webkit-animation: intro-element-slide-lr-short 1s 1 ease-in-out forwards;
  animation: intro-element-slide-lr-short 1s 1 ease-in-out forwards;
}
.slide-rl-short.animated{
  -webkit-animation: intro-element-slide-rl-short 1s 1 ease-in-out forwards;
  animation: intro-element-slide-rl-short 1s 1 ease-in-out forwards;
}
.slide-lr-full.animated{
  -webkit-animation: intro-element-slide-lr-full 1s 1 ease-in-out forwards;
  animation: intro-element-slide-lr-full 1s 1 ease-in-out forwards;
}
.slide-rl-full.animated{
  -webkit-animation: intro-element-slide-rl-full 1s 1 ease-in-out forwards;
  animation: intro-element-slide-rl-full 1s 1 ease-in-out forwards;
}