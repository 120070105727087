@import 'scss/fonts.scss';
@import 'scss/keyframes.scss';
@import 'scss/mixins.scss';
@import 'scss/colors.scss';
@import 'scss/base.scss';

.about {
  padding-bottom: 3rem;
  padding-top: 10px;

  @include gradient-animation(#fff, $main, #424242, $main-dark, 1s, 85%);

  @include for-phone-only {
    @include gradient-animation(#fff, $main, #424242, $main-dark, 1s, 60%);
  }

  .about-line {
    height: 180px;
    width: 1px;
    margin: 1.5rem auto;

    @media (max-width: 800px) {
      height: 130px;
    }
  
    &:before {
      height: 0px;
      width: 1px;
      content: "";
      display: block;
      background-color: $main;
      -webkit-transition: 1s ease-in-out all;
      transition: 1s ease-in-out all;

      .dark & {
        background-color: $text-dark;
      }
    }
  
    &.animate:before {
      height: 180px;
    }
  }
  .animate .about-line:before {
    height: 180px;

    @media (max-width: 800px) {
      height: 130px;
    }
  }

  h1 {
    text-align: center;
    font-size: 42px;
    padding: 0;
    font-family: $font-family-headline;
    font-weight: 400;
    // opacity: 0;
    @include margin-viewport(60px, 60px, 30px, 12px);
    margin-bottom: 6rem;
    line-height: 1.2;


    @media (max-width: 1200px) {
      font-size: 35px;
      margin-bottom: 6rem;
    }

    @media (max-width: 800px) {
      margin-bottom: 3rem;
    }

    @media (max-width: 600px) {
      font-size: 28px;
      line-height: 35px;
    }
  }
}

.competency {
  background: $main;
  color: $main-text;
  font-size: 16px;
  position: relative;
  -webkit-transition: 1s ease-in-out all;
  transition: 1s ease-in-out all;

  .dark & {
    background: $main-dark;
    color: $main-text-dark;
  }

  h1 {
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 3rem;
    width: 100%;
    text-align: center;

    @media (max-width: 1200px) {
      font-size: 26px;
    }

    @media ( max-width: 600px ) {
      font-size: 21px;
    }
  }

  h2 {
    text-align: center;
    // margin-top: 10px;
  }

  i {
    font-size: 52px;
    width: 100%;
    text-align: center;
  }

  .buzzwords, .info {
    transition: transform 1s cubic-bezier(0, .52, 0, 1);
    // transition: flex 1s ease-in-out;
    // min-height: 250px;
  
    .box {
      display: flex;
      flex-direction: column;

      @include for-phone-only {
        margin-bottom: 1rem;
      }
      @include for-tablet-portrait-up {
        flex-direction: row;
        min-height: 220px;
      }
      @include for-tablet-landscape-up {
        min-height: 270px;
      }
      @include for-desktop-up {
        min-height: 220px;
      }
    }
    .buzzwords, .info {
      // width: 100%;
      @include for-tablet-portrait-up {
        position: absolute;
        top: 120px;
        bottom: 0px;
        z-index: 1;
      }
    }
  }
  .buzzwords {
    .info {
      @include for-tablet-portrait-up {
        transform: translate3d(100vw, 0, 0);
        // margin-left: -9999px;
        flex: 0 0 0;
      }
    }
    .buzzwords {
      @include for-tablet-portrait-up {
        transform: translate3d(0vw, 0, 0);
        overflow: hidden;
        flex: 0 0 100%;
        padding-right: 1rem;
      }
    }
  }
  
  .info {
    .buzzwords {
      @include for-tablet-portrait-up {
        transform: translate3d(-100vw, 0, 0);
        // margin-left: -9999px;
        flex: 0 0 0;
      }
    }
    .info {
      @include for-tablet-portrait-up {
        transform: translate3d(0vw, 0, 0);
        overflow: hidden;
        flex: 0 0 100%;
        padding-right: 1rem;
      }
    }
  }
  // .buzzwords {
  //   .info {
  //     transform: translate3d(-100vw, 0, 0);
  //     // margin-left: -9999px;
  //     flex: 0 0 0;
  //     width: 0;
  //     height: 0;
  //   }
  //   .buzzwords {
  //     transform: translate3d(0vw, 0, 0);
  //     overflow: hidden;
  //     flex: 0 0 100%;
  //   }
  // }
  
  // .info {
  //   .buzzwords {
  //     transform: translate3d(200vw, 0, 0);
  //     // margin-left: -9999px;
  //     flex: 0 0 0;
  //     width: 0;
  //     height: 0;
  //   }
  //   .info {
  //     transform: translate3d(0vw, 0, 0);
  //     overflow: hidden;
  //     flex: 0 0 100%;
  //   }
  // }

  a {
    align-self: center;
    margin-bottom: 60px;
  }
}

.light {
  color: $text;
  transition: 1s ease-in-out all;
}

.dark {
  color: $text-dark;
  background: $background-dark;
  transition: 1s ease-in-out all;
}

.two-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 26px;
  font-family: $font-family;

  @include margin-viewport(60px, 60px, 30px);

  div {
    display: flex;
    flex-grow: 1;
    width: 50%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include for-phone-only {
      width: 100%;
    }

    img {
      width: 100%;
      object-fit: cover;
      height: 480px;
    }

    p {
      max-width: 600px;
      padding: 0 30px;
      margin: 1rem 0;

      @media (max-width: 1080px) {
        font-size: 20px;
      }

      @include for-phone-only {
        margin: 1.5rem 0 1rem;
        color: $main-text;
        padding: 0;
        text-align: center;

        .dark & {
          color: $main-text-dark;
        }
      }
    }
  }
}

.docker:before {
  content: "\f395";
  font-family: 'Font Awesome 5 Free';
  display: inline-block;
}


.fab,.fas{-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;display:inline-block;font-style:normal;font-variant:normal;text-rendering:auto;line-height:1}
.fab {font-family:"Font Awesome 5 Brands"; font-weight: 400}
.fas {font-family:"Font Awesome 5 Free"; font-weight:900}

.fa-react:before{content:"\f41b"}.fa-reacteurope:before{content:"\f75d"}
.fa-laptop:before{content:"\f109"}.fa-laptop-code:before{content:"\f5fc"}
.fa-docker:before{content:"\f395"}
.fa-comments:before{content:"\f086"}