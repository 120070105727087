@import '../../scss/colors.scss';
@import '../../scss/mixins.scss';
.footer {
  background: $background-dark;
  color: $white;
  display: flex;
  @include padding-viewport(0 60px, 0 60px, 0 30px, 0 15px);
  transition: 1s ease-in-out all;

  @include for-phone-only {
    text-align: center;
  }

  .logo {
    width: 150px;
  }

  .copyright {
    text-align: center;
    font-size: 15px;
    padding: 24px 15px;
    margin: 0;

    @media (max-width: 1199px) {
      font-size: 14px;
    }

    a {
      color: $white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: $white;
      }
    }
  }

  &.dark { background: $background-darker; }
  &.light {}
}