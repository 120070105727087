@import 'scss/mixins.scss';
@import 'scss/fonts.scss';
@import 'scss/colors.scss';

.link {
  display: flex;
  text-align: center;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  position: relative;
  padding: 18px 56px;
  line-height: 18px;
  z-index: 0;
  overflow: hidden;
  background: -webkit-gradient(linear, right top, left top, color-stop(50%, rgba(0, 0, 0, 0)), color-stop(50%, $main));
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, $main 50%);
  background-size: 201% 100%;
  background-position: right bottom;
  background-repeat: no-repeat;
  -webkit-transition: .25s .35s ease-in-out all;
  transition: .25s .35s ease-in-out all;
  margin: 2rem 0 1rem;
  align-self: flex-end;
  font-size: 18px;
  text-transform: uppercase;
  font-family: $font-family-headline;
  text-decoration: none;
  color: $text;
  margin-right: -1px;
  border: 0;

  :global(.dark) & {
    color: $text-dark;
  }

  &:hover {
    background-position: left bottom;
    color: $main-text;

    &:before {
      left: calc(100% - 40px);
      color: $main-text;
    }

    &:after { left: 100%; }
  }

  &:before {
    position: absolute;
    left: 25px;
    right: auto;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome 5 Free';
    content: "\f061";
    font-weight: 900;
    font-size: 18px;
    -webkit-transition: .4s .1s ease-in-out all;
    transition: .4s .1s ease-in-out all;
  }

  &:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    display: inline-block;
    width: 50%;
    height: 100%;
    // background-color: darken($background, 5%);
    background-color: scale-color($background, $lightness: -5%);
    z-index: -1;
    -webkit-transition: .5s ease-in-out all;
    transition: .5s ease-in-out all;

    @include for-tablet-portrait-up {
      :global(.dark) & {
        background-color: scale-color($background-dark, $lightness: 5%);
      }
    }
  }
  
  @include for-phone-only {
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, $main-text 50%);
    background-size: 201% 100%;
    background-position: right bottom;
    color: $main-text;

    &:hover {
      background-position: left bottom;
      color: $main;

      &:before {
        color: $main;
      }
    }

    &:after {
      background-color: scale-color($main, $lightness: -5%);
    }

    &.dark {
      background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, $main-text-dark 50%);
      background-size: 201% 100%;
      background-position: right bottom;
      color: $main-text-dark;
  
      &:hover, &:hover:before {
        color: $main-dark;
        background-position: left bottom;
      }
  
      &:after {
        background-color: scale-color($main-dark, $lightness: 5%);
      }
    }
  }

  &.contact-card {
    color: $main2-text-dark;
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, $main2-text-dark 50%);
    background-position: right bottom;
    background-size: 201% 100%;
  
    &:hover {
      color: $main2-dark;
      background-position: left bottom;

      &:before {
        color: $main2-dark;
      }
    }

    &:after {
      background-color: scale-color($main2-dark, $lightness: 5%);
    }

    &.light {
      color: $main2-text;
      background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, $main2-text 50%);
      background-position: right bottom;
      background-size: 201% 100%;

      &:hover {
        color: $main2;
        background-position: left bottom;

        &:before {
          color: $main2;
        }
      }
      &:after {
        // background-color: darken($main2, 5%);
        background-color: scale-color($main2, $lightness: -5%);
      }
    }
  }
  &.competency {
    @include for-phone-only {
      display: none;
    }
    color: $main-text;
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, $main-text 50%);
    background-position: right bottom;
    background-size: 201% 100%;

    &.dark {
      color: $main-text-dark;
      background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, $main-text-dark 50%);
      background-position: right bottom;
      background-size: 201% 100%;
      &:hover, &:hover:before {
        color: $main-dark;
      }
      &:after {
        // background-color: darken($main, 5%);
        background-color: scale-color($main-dark, $lightness: 5%);
      }
    }
  
    &:hover {
      color: $main;
      background-position: left bottom;

      &:before {
        color: $main;
      }
    }

    &:after {
      // background-color: darken($main, 5%);
      background-color: scale-color($main, $lightness: -5%);
    }
  }

  &.contact-form {
    color: $main-text;
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, $main-text 50%);
    background-position: right bottom;
    background-size: 201% 100%;
    outline: unset;
    align-self: center;

    &:focus {
      outline: unset;
    }

    &.dark {
      color: $main-text-dark;
      background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, $main-text-dark 50%);
      background-position: right bottom;
      background-size: 201% 100%;
      &:hover, &:hover:before {
        color: $main-dark;
      }
      &:after {
        background-color: scale-color($main-dark, $lightness: 5%);
      }
    }
  
    &:hover {
      color: $main;
      background-position: left bottom;

      &:before {
        color: $main;
      }
    }
  }
}