@import '../../scss/colors.scss';
@import '../../scss/mixins.scss';

.dark {
  @include dark-base();
}

.light {

}

.content {
  p {
    font-size: 20px;
    @include for-tablet-landscape-up {
      font-size: 22px;
    }
    @include for-desktop-up {
      font-size: 25px;
    }
  }

  .about-intro {
    @include gradient-animation(#fff, $main3, #424242, $main3-dark, 1s, 40%, true);
    overflow: hidden;

    .text, .text2 {
      padding-left: 2rem;

      p {
        max-width: 600px;
        padding: 1.5rem 1rem 0;
        margin-left: auto;
      }
      @include for-phone-only {
        padding: 0 2rem;
      }
    }

    .text2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 0;

      p {
        margin-left: 0;

        @include for-phone-only {
          padding-top: 0;
          margin-top: .5rem;
        }
      }
      h2 {
        display: none;

        @include for-phone-only {
          display: block;
          padding: 1.5rem 1rem 0;
        }
      }
    }

    .img img {
      height: 540px;
    }

    .img2 {
      text-align: right;
      img {
        max-width: 340px;
        position: relative;
        right: 150px;
        top: -200px;
      }
    } 

    @include for-phone-only {
      padding-bottom: 3rem;

      .img {order: 1; width: 100%; margin: 0 3rem 0 2rem; overflow: hidden;}
      .text {width: 100%; order: 2; padding: 0 2.5rem 0;}
      .text2 { width: 100%; padding: 0 .5rem 0; }
      .img2 {display: none;}
    }
  }

  h1 {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 700;
    width: 100%;
    text-align: center;
    margin-top: 0;
    margin-bottom: 1rem;

    @include for-tablet-portrait-up {
      font-size: 26px;
    }
    @include for-desktop-up {
      font-size: 32px;
    }
  }
  h2 {
    font-size: 24px;
    text-transform: uppercase;
    padding-top: 15px;
    margin: 0 0 .5rem;
  }

  .introheadline {
    margin: 0;
  }
  .values {
    h2 {
      margin-top: 0;
      font-weight: 600;
      padding: 0;
    }
    p {
      margin: 0 0 1rem;
    }
    div {
      padding-bottom: 2rem;
    }
    div:nth-child(1) {
      color: scale-color($main, $lightness: -45%);
    }
    div:nth-child(2) {
      // color: $main2;
      color: scale-color($main2, $lightness: -50%);
    }
    div:nth-child(4) {
      color: scale-color($main4, $lightness: -20%);;
    }

    .dark & {
      div:nth-child(1) {
        color: $main;
      }
      div:nth-child(2) {
        // color: $main2;
        color: $main2;
      }
      div:nth-child(4) {
        color: $main4;
      }      
    }
  }
}

.clients {
  max-width: 1400px;
  @include padding-viewport(0 60px, 0 60px, 0 30px, 0  15px);
  margin: 0 auto;
  text-align: center;
  width: 100%;

  .intro {
    max-width: 600px;
    margin: 0 auto;
    padding: 5rem 0 3rem;
    text-align: center;
  }
  img, svg {
    margin: 42px;
    max-height: 80px;
    max-width: 230px;
    vertical-align: middle;
    text-align: center;
  }
  svg:not(:hover) path, svg:not(:hover) polygon {
    fill: $text-dark;
  }
  .dark & {
    svg path, svg polygon {
      fill: $text-dark;
    }
  }
  // svg:hover {
  //   path, polygon {
  //     fill: inherit;
  //   }
  // }
}

.line {
  &:after {
    content: '';
    display: block;
    position: absolute;
    background-color: $main-text;
    height: 1px;
    width: 5000px;
    right: 200%;
    top: 0;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
  }
  &.animated:after{
    right: 60%;
  }
  // &:global(.animated) {
  //   opacity: 1 !important;
  //   animation-duration: 0s;
  // }
}