@import '../../scss/mixins.scss';

.logo {
  height: 100px;
  background: url('/img/logo-gray.svg') no-repeat top left / contain;
  transition: 1s ease-in-out all;
  margin: 0 20px;

  &.dark {
    background: url('/img/logo.svg') no-repeat top left / contain;
  }
  &.center {
    background-position: center;
  }

  @include for-phone-only {
    margin: 0 40px;
  }
}