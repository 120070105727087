@-webkit-keyframes intro-element {
  from {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
  }
  to {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
  }
}
@keyframes intro-element {
  from {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
  }
  to {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
  }
}
@-webkit-keyframes intro-element-reverse {
  from {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
  }
  to {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
  }
}
@keyframes intro-element-reverse {
  from {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
  }
  to {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
  }
}
@-webkit-keyframes intro-element-slide-lr {
  from {
      -webkit-transform: translateX(-50px);
      transform: translateX(-50px);
      opacity: 0;
  }
  to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
  }
}
@keyframes intro-element-slide-lr {
  from {
      -webkit-transform: translateX(-50px);
      transform: translateX(-50px);
      opacity: 0;
  }
  to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
  }
}
@-webkit-keyframes intro-element-slide-lr-short {
  from {
      -webkit-transform: translateX(-25px);
      transform: translateX(-25px);
      opacity: 0;
  }
  to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
  }
}
@keyframes intro-element-slide-lr-short {
  from {
      -webkit-transform: translateX(-25px);
      transform: translateX(-25px);
      opacity: 0;
  }
  to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
  }
}
@-webkit-keyframes intro-element-slide-rl {
  from {
      -webkit-transform: translateX(50px);
      transform: translateX(50px);
      opacity: 0;
  }
  to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
  }
}
@keyframes intro-element-slide-rl {
  from {
      -webkit-transform: translateX(50px);
      transform: translateX(50px);
      opacity: 0;
  }
  to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
  }
}
@-webkit-keyframes slide-rl-full {
  from {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
      opacity: 0;
  }
  to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
  }
}
@keyframes slide-rl-full {
  from {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
      opacity: 0;
  }
  to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
  }
}
@-webkit-keyframes slide-lr-full {
  from {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 0;
  }
  to {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
      opacity: 1;
  }
}
@keyframes slide-lr-full {
  from {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 0;
  }
  to {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
      opacity: 1;
  }
}
@-webkit-keyframes moving_lines {
  from {
      stroke-dashoffset: 0;
  }
  to {
      stroke-dashoffset: 480;
  }
}
@keyframes moving_lines {
  from {
      stroke-dashoffset: 0;
  }
  to {
      stroke-dashoffset: 480px;
  }
}
@-webkit-keyframes fade {
  40% {
      opacity: 0.8;
  }
  42% {
      opacity: 0.1;
  }
  43% {
      opacity: 0.8;
  }
  45% {
      opacity: 0.1;
  }
  46% {
      opacity: 0.8;
  }
}
@keyframes fade {
  40% {
      opacity: 0.8;
  }
  42% {
      opacity: 0.1;
  }
  43% {
      opacity: 0.8;
  }
  45% {
      opacity: 0.1;
  }
  46% {
      opacity: 0.8;
  }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
  }
  @keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
  }
@-webkit-keyframes FillIn {
  from {
      fill-opacity: 0;
  }
  to {
      fill-opacity: 1;
      stroke-opacity: 0;
      stroke-width: 0;
  }
}
@keyframes FillIn {
  from {
      fill-opacity: 0;
  }
  to {
      fill-opacity: 1;
      stroke-opacity: 0;
      stroke-width: 0;
  }
}
@-webkit-keyframes RGBshift {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      text-shadow: 5px 10px 0px rgb(245, 245, 241);
      opacity: 1;
  }
  12% {
      text-shadow: none;
      opacity: 1;
  }
  58% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      text-shadow: none;
      opacity: 1;
  }
  59% {
      text-shadow: -5px -8px 0px rgb(245, 245, 241);
      opacity: 0.6;
  }
  62% {
      -webkit-transform: rotate(-0.5deg) scale(1.01);
      transform: rotate(-0.5deg) scale(1.01);
      text-shadow: 8px -2px 0px rgba(255, 0, 0, 0.5),
          -5px -2px 0px rgba(0, 255, 0, 0.5),
          2px -2px 0px rgba(0, 0, 255, 0.5);
      opacity: 1;
  }
  64% {
      -webkit-transform: rotate(-0.75deg) scale(1.01);
      transform: rotate(-0.75deg) scale(1.01);
  }
  67% {
      text-shadow: none;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
}
@keyframes RGBshift {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      text-shadow: 5px 10px 0px rgb(245, 245, 241);
      opacity: 1;
  }
  12% {
      text-shadow: none;
      opacity: 1;
  }
  58% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      text-shadow: none;
      opacity: 1;
  }
  59% {
      text-shadow: -5px -8px 0px rgb(245, 245, 241);
      opacity: 0.6;
  }
  62% {
      -webkit-transform: rotate(-0.5deg) scale(1.01);
      transform: rotate(-0.5deg) scale(1.01);
      text-shadow: 8px -2px 0px rgba(255, 0, 0, 0.5),
          -5px -2px 0px rgba(0, 255, 0, 0.5),
          2px -2px 0px rgba(0, 0, 255, 0.5);
      opacity: 1;
  }
  64% {
      -webkit-transform: rotate(-0.75deg) scale(1.01);
      transform: rotate(-0.75deg) scale(1.01);
  }
  67% {
      text-shadow: none;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
}

@-webkit-keyframes pulse-ring {
  0% {
      -webkit-transform: scale(0.33);
      transform: scale(0.33);
  }
  80%,
  100% {
      opacity: 0;
  }
}
@keyframes pulse-ring {
  0% {
      -webkit-transform: scale(0.33);
      transform: scale(0.33);
  }
  80%,
  100% {
      opacity: 0;
  }
}

@-webkit-keyframes pulse-dot {
  0% {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
  }
  50% {
      -webkit-transform: scale(1);
      transform: scale(1);
  }
  100% {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
  }
}
@keyframes pulse-dot {
  0% {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
  }
  50% {
      -webkit-transform: scale(1);
      transform: scale(1);
  }
  100% {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
  }
}