@import 'scss/fonts.scss';
@import 'scss/keyframes.scss';
@import 'scss/mixins.scss';
@import 'scss/colors.scss';
@import 'scss/base.scss';


.contact-form {
  position: relative;
  padding-top: 84px;
  // height: 60vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  transition: all 1s ease-in-out;
  background: $white;

   .dark & {
     background: $background-dark;
   }

  .background {
    position: absolute;
    background-color: #FBF3F5;
    background: scale-color($main4, $lightness: 95%);
    width: 100%;
    height: 100%;
    right: 0;
    top: 84px;
    -webkit-animation: slide-rl-full .5s 1 ease-in-out forwards;
    animation: slide-rl-full .5s 1 ease-in-out forwards;
    transition: all 1s ease-in-out;

    @media (min-width: 600px) {
      width: 88.5%;
    }
    @media (min-width: 1200px) {
      width: 80%;
    }

    .dark & {
      background: $background-darker;
    }
  }

  .form-container {
    width: 83.34%;
    @media (min-width: 600px) {
      width: 66.67%;
    }
    @media (min-width: 1200px) {
      width: 50%;
    }
    z-index: 1;
    padding: 42px 15px;
    opacity: 0;

    &.animated {
      -webkit-animation: intro-element 1s 1s ease-in-out forwards;
      animation: intro-element 1s 1s ease-in-out forwards;
    }

    .dark & {
      color: $text-dark;
    }

    h2 {
      padding: 42px 0;
      margin: 0;
      font-size: 32px;
      text-transform: uppercase;
      text-align: center;
      font-weight: 500;
      color: $main4;
    }

    p {
      margin: 0;
      padding-bottom: 1rem;
      font-size: 24px;
      text-align: center;
      font-family: $font-family-headline;
    }

    .form {
      position: relative;
      padding-top: 100px;
      margin-top: 3rem;

      :global(.animated) {
        &:after {
          content: '';
          display: block;
          position: absolute;
          background-color: $main;
          height: 1px;
          width: 5000px;
          right: 200%;
          top: 0;
          -webkit-transition: all 1s ease;
          transition: all 1s ease;
        }
      }
      .form-line:global(.animated):after{
        right: 95%;
      }
    }
  }
}