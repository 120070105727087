$font-family-headline: 'Maven Pro', sans-serif;
$font-family: 'Open Sans', sans-serif;
$font-family2: 'Montserrat', sans-serif;


$fontUrl: "https://use.fontawesome.com/releases/v5.13.0/webfonts";
@font-face {font-family:"Font Awesome 5 Brands";font-style:normal;font-weight:400;font-display:block;src:url($fontUrl + '/fa-brands-400.eot');src:url($fontUrl+'/fa-brands-400.eot?#iefix') format("embedded-opentype"),url($fontUrl+'/fa-brands-400.woff2') format("woff2"),url($fontUrl+'/fa-brands-400.woff') format("woff"),url($fontUrl+'/fa-brands-400.ttf') format("truetype"),url($fontUrl+'/fa-brands-400.svg#fontawesome') format("svg")}

@font-face{font-family:"Font Awesome 5 Free";font-style:normal;font-weight:400;font-display:block;src:url($fontUrl+'/fa-regular-400.eot');src:url($fontUrl+'/fa-regular-400.eot?#iefix') format("embedded-opentype"),url($fontUrl+'/fa-regular-400.woff2') format("woff2"),url($fontUrl+'/fa-regular-400.woff') format("woff"),url($fontUrl+'/fa-regular-400.ttf') format("truetype"),url($fontUrl+'/fa-regular-400.svg#fontawesome') format("svg")}

@font-face{font-family:"Font Awesome 5 Free";font-style:normal;font-weight:900;font-display:block;src:url($fontUrl+'/fa-solid-900.eot');src:url($fontUrl+'/fa-solid-900.eot?#iefix') format("embedded-opentype"),url($fontUrl+'/fa-solid-900.woff2') format("woff2"),url($fontUrl+'/fa-solid-900.woff') format("woff"),url($fontUrl+'/fa-solid-900.ttf') format("truetype"),url($fontUrl+'/fa-solid-900.svg#fontawesome') format("svg")}