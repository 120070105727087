@import '../../scss/mixins.scss';

.container {
  @include dark-base();
}

.personvern {
  overflow: hidden;
  padding-top: 2rem;

  h2 {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 500;
    padding-top: 15px;
    margin: 0 0 .5rem;
  }

  p {
    margin: 0 0 1rem;
  }
}