@import '../../scss/colors.scss';
@import '../../scss/keyframes.scss';
@import '../../scss/mixins.scss';

$color: $main2-text;

#header-menu {
  position: fixed;
  right: 25px;
  top: 25px;
  width: 60px;
  height: 60px;
  z-index: 120;
  cursor: pointer;
  &:focus {
    outline: none;
  }

  @include for-phone-only {
    right: 15px;
    top: 15px;
  }

  .circle-bg {
    width: 60px;
    height: 60px;
    background-color: #fff;
    border-radius: 50%;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    -webkit-transition-property: background-color, -webkit-transform;
    transition-property: background-color, -webkit-transform;
    transition-property: background-color, transform;
    transition-property: background-color, transform, -webkit-transform;
    -webkit-transition-duration: .5s, .5s;
    transition-duration: .5s, .5s;
    -webkit-transition-delay: 0, .5s;
    transition-delay: 0, .5s;
  
    &:focus {
      outline: none;
    }
    /*    transition: transform 0.3s ease-in-out, background-color 0.3s 0.3s ease-in-out;*/
  }

  &.open {
    #nav-icon {
      span:nth-child(1) {
        -webkit-transform: translateY(9px) rotate(-135deg);
        transform: translateY(9px) rotate(-135deg);
      }
      span:nth-child(2) {
        left: 150%;
        width: 400%;
      }
      span:nth-child(3) {
        -webkit-transform: translateY(-9px) rotate(-225deg);
        transform: translateY(-9px) rotate(-225deg);
      }
      span {
        background: $color;
      }
    }
    .circle-bg {
      background-color: transparent;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-transform: scale(0);
      transform: scale(0);
      /*    transition: transform 0.3s ease-in-out, background-color 0.3s 0.3s ease-in-out;*/
      -webkit-transition-property: background-color, -webkit-transform;
      transition-property: background-color, -webkit-transform;
      transition-property: transform, background-color;
      transition-property: transform, background-color, -webkit-transform;
      -webkit-transition-duration: .5s, .5s;
      transition-duration: .5s, .5s;
      -webkit-transition-delay: 0, .5s;
      transition-delay: 0, .5s;
    }
  }

  &.dark:not(.open) {
    .circle-bg { background-color: $background-dark; }
  }
}
#header-logo,
#header-menu {
  -webkit-transition: all .6s ease-in-out;
  transition: all .6s ease-in-out;
}
#header-logo.nav-up {
  position: fixed;
}
#header-menu.nav-up {
  right: -200px;
}
#nav-icon {
  position: absolute;
  width: 30px;
  height: 22px;
  left: 0;
  top: 4px;
  right: 0;
  bottom: 0;
  margin: auto;
  color: $text;

  &:focus {
    outline: none;
  }

  span {
    position: absolute;
    width: 100%;
    left: 0;
    height: 2px;
    background: #000;

    .dark & { background: #fff; }

    &:nth-child(1) {
      top: 0px;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }
    &:nth-child(2) {
      top: 9px;
      -webkit-transition: width 0.3s ease-in-out, left 0.3s 0.2s;
      transition: width 0.3s ease-in-out, left 0.3s 0.2s;
    }
    &:nth-child(3) {
      top: 18px;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }
  }
}
.dark#header-menu.open #nav-icon span {
  background: $text-dark;
}
/*
#header-menu:hover span:nth-child(2) {
  transform: translateX(3px);
}
*/


/*
MENU
*/
.main-nav-ul {
  font-size: 35px;
  font-weight: 700;
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;

  @media (min-width: 1199px) {
    font-size: 45px;
  }

  @media (max-width: 600px) {
    font-size: 28px;
    line-height: 35px;
  }

  li {
    position: relative;
  
    .animate{
      animation-duration: .3s !important;
      -webkit-animation: intro-element .25s .1s ease-in-out forwards;
      animation: intro-element .25s .1s ease-in-out forwards;
    }
    :global(.animated) {
      opacity: 0;
    }

    a {
      padding-bottom: 30px;
      display: block;
    }
    &:last-child a {
      padding-bottom: 0;
    }
  }
  a {
    position: relative;
    /*    color: #d33d27;*/
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-decoration: none;

    .dark & {
      color: $main2-text-dark;
    }
    
    @include for-desktop-up {
      transform: translateX(-74px);
      transition: transform .2s cubic-bezier(.4,0,.2,1);
      will-change: transform;

      &:before {
        font-family: 'Font Awesome 5 Free';
        content: "\f061";
        font-weight: 900;
        // font-size: 18px;
        padding-right: 10px;
        float: left;
        opacity: 0;
        transition: opacity .2s cubic-bezier(.4,0,.2,1);
        will-change: opacity;
        width: 68px;
        height: 57px;
        display: block;
        overflow: hidden;
      }
    }

    &:hover {
      color: #fff;

      @include for-desktop-up {
        transition: transform .2s cubic-bezier(.4,0,.2,1);
        transform: translateX(0);
        color: $color;
        .dark & {
          color: $main2-text-dark;
        }
      }

      &:before {
        opacity: 1;
      }
    }
  }
}


.menu-container {
  position: fixed;
  width: 100vw;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  // display: none;
  visibility: hidden; 
  z-index: 110;
  overflow: auto;
  background: $main3 no-repeat center center fixed;
  background-size: cover;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  overflow: hidden;

  &.dark {
    background-color: $main2-dark;

    a {
      color: $text-dark;

      &:hover {
        color: $link-hover;
      }
    }

    .reveal {
      background: $main2-dark;
    }
  }

  &.active {
    // display: block;
    visibility: visible;
    opacity: 1; 
  }

  .logo {
    position: absolute;
    top: 30px;
    left: 60px;
    // -webkit-transition: all .6s ease-in-out;
    // transition: all .6s ease-in-out;
    animation: fadeIn 3s;

    @media (max-width: 800px) {
      left: 30px;
    }

    img {
      width: 200px;
    }
  }
}
.full-width-menu-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 60px;
  /*    margin-top: 120px;*/

  // @media (max-width: 1000px) {
  //   text-align: center;
  //   justify-content: center;
  // }

  .column {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    @include for-phone-only {
      flex-direction: column;
      text-align: center;
    }

    .extra {
      @include for-phone-only {
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
      }
    }
  }
}

.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  z-index: -2;
}
.bg-image.active {
  opacity: 1;
}
// .bg-image.select-1 {
//   background: url('../img/select-1.jpg') no-repeat center center fixed;
//   background-size: cover;
// }
// .bg-image.select-2 {
//   background: url('../img/select-2.jpg') no-repeat center center fixed;
//   background-size: cover;
// }
// .bg-image.select-3 {
//   background: url('../img/select-3.jpg') no-repeat center center fixed;
//   background-size: cover;
// }
// .bg-image.select-4 {
//   background: url('../img/select-4.jpg') no-repeat center center fixed;
//   background-size: cover;
// }
.line-height-60 {
  line-height: 60px;
}

.videos {
  position: absolute;
  top: 0;
  bottom: 0;
  // left: 0;
  // left: 256px;
  left: 33.34%;
  right: 0;
  overflow: hidden;
  z-index: -1;
  display: none;

  @include for-desktop-up {
    display: block;
  }

  .video {
    position: relative;
    width: 100%;
    height: 480px;
    margin: 156px 0 215px;
    display: block;
    overflow: hidden;
    position: absolute;
    height: 100%;
    margin: 0;
    opacity: 0;
    transition: opacity .2s cubic-bezier(.4,0,.2,1);
    will-change: opacity;
    transform: translateZ(0);
    z-index: -1;
    background: black;
    // overflow: hidden;

    &.left {
      left: 0;
    }

    iframe {
      pointer-events: none;
      border: 0;
      width: 100vw;
      height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
      min-height: 100vh;
      min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;

      @include for-desktop-up {
        display: block;
      }
    }
  
    &.active {
      opacity: 1;
    }
  }

  .reveal {
    width: 100%;
    background: $main3;
    transition: width .2s cubic-bezier(.4,0,.2,1);
    will-change: width;
    transform: translateZ(0);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transition: all .3s ease-in-out;

    &.show {
      width: 0;
    }
  }
}