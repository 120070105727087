@import '../../scss/mixins.scss';

.columns {
  flex-direction: row;
  margin: 0 -1rem;
  padding: 0 6rem;
  flex-flow: wrap;
  align-content: space-between;

  @include padding-viewport(0 60px, 0 60px, 0 30px);

  & > * {
    padding: 0 1rem;
  }
  &-2 > *{
    width: 50%;
  }
  &-3 > * {
    width: 33.3%;
  }
  &-4 > * {
    @include for-phone-only {
      width: 100%;
    }
    @include for-tablet-portrait-up {
      width: 50%;
    }
    @include for-desktop-up {
      width: 25%;
    }
  }
  &-5 > * {
    width: 20%;
  }

  &.no-padding {
    padding: 0;
  }

  &.halve {
    @include for-tablet-landscape-up {
      padding: 0 25%;
    }

    &.columns-4 > * {
      width: 50%;

      @include for-phone-only {
        width: 100%;
      }
    }
  }
}