@import 'colors.scss';

@mixin gradient-animation( $start, $end, $darkStart, $darkEnd, $transTime, $percent, $colorTop: false ){
  background-size: 100%;
  // background-image: linear-gradient($start, $end);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop($percent, $start), color-stop($percent, $end), to($end));
  background-image: linear-gradient(to bottom, $start $percent, $end $percent, $end 100%);
  position: relative;
  z-index: 100;
  &:before {
    // background-image: linear-gradient($end, $start);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop($percent, $darkStart), color-stop($percent, $darkEnd), to($darkEnd));
    background-image: linear-gradient(to bottom, $darkStart $percent, $darkEnd $percent, $darkEnd 100%);
    content: '';    
    display: block;
    height: 100%;
    position: absolute;
    top: 0; left: 0;
    opacity: 0;
    width: 100%;
    z-index: -100;
    transition: opacity $transTime ease-in-out;
  }

  @if $colorTop {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop($percent, $end), color-stop($percent, $start), to($start));
    background-image: linear-gradient(to bottom, $end $percent, $start $percent, $start 100%);
    &:before {
      background-image: -webkit-gradient(linear, left top, left bottom, color-stop($percent, $darkEnd), color-stop($percent, $darkStart), to($darkStart));
      background-image: linear-gradient(to bottom, $darkEnd $percent, $darkStart $percent, $darkStart 100%);
    }
  }

  .dark &, &.dark {
     &:before {
       opacity: 1; 
     }
  }
}

@mixin margin-viewport($full, $w1200: null, $w800: null, $w480: null) {
  margin: 0 $full;
  @if $w1200 {
    @media (max-width: 1200px) {
      margin: 0 $w1200;
    }
  }
  @if $w800 {
    @media (max-width: 800px) {
      margin: 0 $w800;
    }
  }
  @if $w480 {
    @media (max-width: 480px) {
      margin: 0 $w480;
    }
  }
}

@mixin padding-viewport($full, $w1200: null, $w800: null, $w480: null) {
  padding: $full;
  @if $w1200 {
    @media (max-width: 1200px) {
      padding: $w1200;
    }
  }
  @if $w800 {
    @media (max-width: 800px) {
      padding: $w800;
    }
  }
  @if $w480 {
    @media (max-width: 480px) {
      padding: $w480;
    }
  }
}

@mixin padding-vertical() {

}

@mixin dark-base() {
  transition: all 1s ease-in-out;
  &.dark {
    color: $text-dark;
    background: $background-dark;

    a {
      color: $main3;

      &:hover {
        color: lighten($main3, 25%);
      }
    }
  }
}

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}