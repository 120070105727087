@import './colors.scss';
@import './fonts.scss';

body {
  font-size: 18px;
  line-height: 1.5;
  color: $text;
  font-family: $font-family;

  &:global(.menu-visible) {
    overflow: hidden;
  }
}

h1, h2, h3 {
  font-family: $font-family-headline;
  font-weight: 400;
}

.dark {
  color: $text-dark;
  background: $background-dark;
}

a {
  color: $text;
  // text-decoration: none;

  :global(.dark) {
    color: $text-dark;
  }

  &:hover {
    color: $link-hover;
  }
}

*, ::after, ::before {
  box-sizing: border-box;
}