@import '../../scss/fonts.scss';
@import '../../scss/colors.scss';
@import '../../scss/keyframes.scss';
@import '../../scss/mixins.scss';
section {
  display: flex;
  flex-direction: column;
  -webkit-transition: 1s ease-in-out all;
  transition: 1s ease-in-out all;
}

.splash {
  width: 100%;
  height: 100vh;
  // padding-top: 10vh;
  // background-image: url('/img/lena-bell-mluSdDeOksc-unsplash-min.jpg');
  background: url('/img/background/ben-white-gEKMstKfZ6w-unsplash-min_wznbsn_ar_1_1,c_fill,g_auto__c_scale,w_600') no-repeat center center / cover;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #424242;
  transition: 1s ease-in-out all;
  overflow: hidden;
  // text-shadow: 2px 2px #000;

  @include for-phone-only {
    background-image: url('/img/background/ben-white-gEKMstKfZ6w-unsplash-min_wznbsn_ar_1_1,c_fill,g_auto__c_scale,w_600.jpg');
  }
  @include for-tablet-portrait-up {
    background-image: url('/img/background/ben-white-gEKMstKfZ6w-unsplash-min_wznbsn_ar_4_3,c_fill,g_auto__c_scale,w_897.jpg');
  }
  @include for-tablet-landscape-up {
    background-image: url('/img/background/ben-white-gEKMstKfZ6w-unsplash-min_wznbsn_ar_16_9,c_fill,g_auto__c_scale,w_1381.jpg');
  }
  @include for-desktop-up {
    background-image: url('/img/background/ben-white-gEKMstKfZ6w-unsplash-min_wznbsn_c_scale,w_2112.jpg');
  }
  @include for-big-desktop-up {
    background-image: url('/img/background/ben-white-gEKMstKfZ6w-unsplash-min_wznbsn_c_scale,w_3840.jpg');
  }

  :global(.webp) & {
    @include for-phone-only {
      background-image: url('/img/background/ben-white-gEKMstKfZ6w-unsplash-min_wznbsn_ar_1_1,c_fill,g_auto__c_scale,w_600.webp');
    }
    @include for-tablet-portrait-up {
      background-image: url('/img/background/ben-white-gEKMstKfZ6w-unsplash-min_wznbsn_ar_4_3,c_fill,g_auto__c_scale,w_897.webp');
    }
    @include for-tablet-landscape-up {
      background-image: url('/img/background/ben-white-gEKMstKfZ6w-unsplash-min_wznbsn_ar_16_9,c_fill,g_auto__c_scale,w_1381.webp');
    }
    @include for-desktop-up {
      background-image: url('/img/background/ben-white-gEKMstKfZ6w-unsplash-min_wznbsn_c_scale,w_2112.webp');
    }
    @include for-big-desktop-up {
      background-image: url('/img/background/ben-white-gEKMstKfZ6w-unsplash-min_wznbsn_c_scale,w_3840.webp');
    }
  }


  &.dark {
    // background-image: url('/img/ben-white-gEKMstKfZ6w-unsplash-min.jpg');
    // background-image: url('/img/lena-bell-mluSdDeOksc-unsplash-min.jpg');
    background: url('/img/background/lena-bell-mluSdDeOksc-unsplash-min_sovk3v_ar_1_1,c_fill,g_auto__c_scale,w_600') no-repeat center center / cover;
    @include for-phone-only {
      background-image: url('/img/background/lena-bell-mluSdDeOksc-unsplash-min_sovk3v_ar_1_1,c_fill,g_auto__c_scale,w_600.jpg');
    }
    @include for-tablet-portrait-up {
      background-image: url('/img/background/lena-bell-mluSdDeOksc-unsplash-min_sovk3v_ar_4_3,c_fill,g_auto__c_scale,w_897.jpg');
    }
    @include for-tablet-landscape-up {
      background-image: url('/img/background/lena-bell-mluSdDeOksc-unsplash-min_sovk3v_ar_16_9,c_fill,g_auto__c_scale,w_1183.jpg');
    }
    @include for-desktop-up {
      background-image: url('/img/background/lena-bell-mluSdDeOksc-unsplash-min_sovk3v_c_scale,w_1734.jpg');
    }
    @include for-big-desktop-up {
      background-image: url('/img/background/lena-bell-mluSdDeOksc-unsplash-min_sovk3v_c_scale,w_2048.jpg');
    }

    :global(.webp) & {
      @include for-phone-only {
        background-image: url('/img/background/lena-bell-mluSdDeOksc-unsplash-min_sovk3v_ar_1_1,c_fill,g_auto__c_scale,w_600.webp');
      }
      @include for-tablet-portrait-up {
        background-image: url('/img/background/lena-bell-mluSdDeOksc-unsplash-min_sovk3v_ar_4_3,c_fill,g_auto__c_scale,w_897.webp');
      }
      @include for-tablet-landscape-up {
        background-image: url('/img/background/lena-bell-mluSdDeOksc-unsplash-min_sovk3v_ar_16_9,c_fill,g_auto__c_scale,w_1183.webp');
      }
      @include for-desktop-up {
        background-image: url('/img/background/lena-bell-mluSdDeOksc-unsplash-min_sovk3v_c_scale,w_1734.webp');
      }
      @include for-big-desktop-up {
        background-image: url('/img/background/lena-bell-mluSdDeOksc-unsplash-min_sovk3v_c_scale,w_2048.webp');
      }
    }
  
    color: #ccc;

    p {
      text-shadow: 1px 1px 1px white;
    }
  }

  h2 {
    font-size: 2.4em;
    margin: 0;
    color: #424242;
    font-family: $font-family-headline;
  }
  p {
    font-size: 1.4em;
    margin: 10px auto 0;
    width: 46%;
    text-shadow: 1px 1px 2px black;
  }
}

.intro {
  height: 90vh;
  background: $main3;
  display: flex;
  justify-content: center;
  align-items: center;

  .text {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;

    h1 {
      font-size: 14vw;
      // color: darken($main, 5%);
      color: scale-color($main3, $lightness: -10%);
      text-transform: uppercase;
      margin: 0;
      font-weight: 800;
      -webkit-transition: 1s ease-in-out all;
      transition: 1s ease-in-out all;

      @media (max-width: 1200px) {
        font-size: 14vw;
      }
    }
    h2 {
      color: $main3-text;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-weight: 300;
      font-size: 42px;
      margin: 0;
      padding-left: 90px;
      padding-right: 15px;
      text-align: left;
      box-sizing: border-box;
      -webkit-transition: 1s ease-in-out all;
      transition: 1s ease-in-out all;
      // opacity: 0;
      
      @media (max-width: 1200px) {
        font-size: 35px;
        padding-left: 60px;
      }
      @media (max-width: 600px) {
        font-size: 28px;
        line-height: 35px;
        padding-left: 30px;
      }

      .animate{
        -webkit-animation: intro-element-slide-lr 1s 1 ease-in-out forwards;
        animation: intro-element-slide-lr 1s 1 ease-in-out forwards;
      }
    }
  }

  .logo {
    position: absolute;
    top: 30px;
    left: 60px;
    // -webkit-transition: all .6s ease-in-out;
    // transition: all .6s ease-in-out;
    animation: fadeIn 3s;

    @media (max-width: 800px) {
      left: 30px;
    }

    img {
      width: 200px;
    }

    @include for-phone-only {
      left: 15px;
      img { width: 125px; }
    }
  }
}

.intro.dark {
  background: $main3-dark;
  // h1 { color: scale-color($main3-dark, $lightness: 10%); }
  h1 { color: scale-color($main3-dark, $lightness: -10%); }
  h2 { color: $main3-text-dark; }

  .logo {
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: url('/img/logo-white.svg') no-repeat;
    width: 200px; /* Width of new image */
    // height: 236px; /* Height of new image */
    padding-left: 200px; /* Equal to width of new image */
    background-size: contain;
    overflow: hidden;
  }
}

.fade {

}