@import '../../scss/fonts.scss';
// @import 'scss/keyframes.scss';
@import '../../scss/mixins.scss';
@import '../../scss/colors.scss';
// @import 'scss/base.scss';

.language-selector {
  position: absolute;
  right: 120px;
  top: 40px;
  z-index: 500;
  color: $text;

  &.dark {
    color: $text-dark;
  }

  .button {
    font-family: $font-family;
    background: transparent;
    border: 0;
    padding: 0;
    font-size: 18px;
    color: inherit;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  span {
    padding: 0 5px;
  }

  .selected {
    font-weight: bold;
  }

  .no:after { content: 'norsk'; }
  .en:after { content: 'english'; }

  @include for-phone-only {
    right: 85px;
    top: 30px;

    .no:after { content: "no"; } 
    .en:after { content: "en"; }
    span { padding: 0 2px; }
  }

  :global(.menu-visible) & {
    bottom: 25px;
    left: 25px;
    top: unset;
    right: unset;
    position: fixed;

    .no:after { content: 'norsk'; }
    .en:after { content: 'english'; }

    @include for-phone-only {
      bottom: 80px;
      left: 0;
      right: 0;
      text-align: center;
    }
  }
}