$main: #9FC29E;
$main2: #FCE1BF;
// $main3: #D9DBAE;
$main3: $main;
$main4: #EF899C;
$main-text: #424242;
$main2-text: #424242;
$main3-text: $main-text;
$main4-text: #fff;
// $main: #704F4F;
// $main-dark: #273B09;
$main-dark: #607466;
$main2-dark: #303030;
// $main3-dark: #1D1128;
$main3-dark: #39393A;
$main4-dark: #EF899C;
$main-text-dark: #F5F5F5;
// $main-text-dark: #C4C4C4;
$main2-text-dark: #ffd1bd;
$main3-text-dark: #fff;
// $main3-text-dark: #EFEEF3;
$main4-text-dark: #fff;
// $main-dark: #704F4F;
// $main-darken5: darken($main, 5%);
$main-darken5: scale-color($main, $lightness: -5%);
$text: #424242;
// $text: #704F4F;
$text-dark: #BDBDBD;
$text-colored: #fff;
$link-hover: #ff0000;
$background: #fff;
$background-dark: #424242;
$background-darker: #343434;
$white: #fff;
// $contrast-color: #ff0000;
// $contrast-color: #d33d42;
$contrast-color: #2a9390;